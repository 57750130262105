.carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 50%; /* Центрирование по вертикали */
}

@media (max-width: 768px) {
    .carousel-control-prev, .carousel-control-next {
        width: 10%; /* Больший размер для лучшего взаимодействия на маленьких экранах */
    }
    .carousel-control-prev-icon, .carousel-control-next-icon {
        width: 20px; /* Уменьшение размера иконок */
        height: 20px; /* Уменьшение размера иконок */
    }
}
