.contact__container{
    min-height: 85vh;
    background-image: url('../images/contactsblur.png');
    background-position: center;
    background-size: cover;
}
.contact__container .container{
    height: 100%;
    background: #d3d3d37d;
    border: 2px solid #00000012;
    border-radius: 10px;
}
.contact__container .lead{
    font-size: 2rem;
    font-weight: 600;
    font-family: monospace;
    color: #000000b3;
}
@media (max-width: 768px) {
/* Уменьшаем отступ сверху и снизу контейнера на мобильных устройствах */
    .contact__container {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  
    /* Уменьшаем отступы внутри контейнера на мобильных устройствах */
    .contact__container .container {
      padding: 15px;
    }
  
    /* Уменьшаем размер шрифта для заголовка на мобильных устройствах */
    .contact__container h2 {
      font-size: 1.5rem;
      margin-top: 2em;
    }
  
    /* Уменьшаем размер шрифта для текста на мобильных устройствах */
    .contact__container p {
      font-size: 1rem;
    }
  
    /* Уменьшаем размер кнопок на мобильных устройствах */
    .contact__container .btn {
      font-size: 1.5rem;
    }
  }