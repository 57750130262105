.navigation__container .nav-link.active {
    color: #FFCF00; /* Жёлтый цвет текста */
    text-shadow: 2px 1px 6px #ffcf00a8; /* Жёлтая тень */
}
@media (max-width: 768px) {
    .navbar-brand {
      font-size: 1.8rem !important; 
      font-weight: bold; 
    }
  }
  